import React, { useState, useMemo } from "react";
import { Alert, Button, Glyphicon, Table } from "react-bootstrap";

import { toIcelandicDate } from "../utils/DateFormat";
import { numberToFixedString } from "../utils/Numbers";
import SortableTableHeader from "./SortableTableHeader";
import EditProject from "./modals/EditProject";
import { translate } from "../utils/Translations";

import "./Transactions.css"; // for table cell centering styles

// prettier-ignore
const translations = {
  "No projects have been created": { is: "Engin verkefni hafa verið búin til" },
  "Name": { is: "Nafn" },
  "Total hours": { is: "Samtals tímar" },
  "Created": { is: "Búið til" },
  "Last entry": { is: "Síðasta færsla" },
  "Edit": { is: "Breyta" },
  "Open/Close": { is: "Opna/Loka" },
  "Close": { is: "Loka" },
  "Open": { is: "Opna" },
  "Open projects": { is: "Opin verkefni" },
  "Closed projects": { is: "Lokuð verkefni" },
};

const t = key => translate(key, translations);

const EmptyProjectTableNoticeRow = () => (
  <tr>
    <td colSpan={9} style={{ textAlign: "center" }}>
      <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
        {t("No projects have been created")}
      </Alert>
    </td>
  </tr>
);

function ProjectsTable({
  projects,
  sortedBy,
  onChangeProjectState,
  onEditProject,
  onSortProjects,
}) {
  const zeroProjects = projects.length === 0;
  const maxProjectNameLength = useMemo(() => {
    return projects.reduce((max, project) => {
      return Math.max(max, project.name.length);
    }, 0);
  }, [projects]);
  const projectNameClass =
    maxProjectNameLength > 20 ? "verticalMiddle" : "cell-center";

  return (
    <Table responsive striped bordered condensed hover>
      <thead>
        <tr>
          <SortableTableHeader
            name={t("Name")}
            attribute="name"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Total hours")}
            attribute="total_working_hours"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Created")}
            attribute="created"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Last entry")}
            attribute="latest_work_log"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <th className="text-center">{t("Edit")}</th>
          <th className="text-center">{t("Open/Close")}</th>
        </tr>
      </thead>
      <tbody>
        {/* Display a notice if the projects table is empty */}
        {zeroProjects ? EmptyProjectTableNoticeRow : null}

        {/* Projects, one per row */}
        {projects.map((item, i) => {
          return (
            <tr key={i}>
              <td className={projectNameClass}>{item.name}</td>
              <td className="cell-center">
                {item.total_working_hours
                  ? numberToFixedString(item.total_working_hours)
                  : null}
              </td>
              <td className="cell-center">{toIcelandicDate(item.created)}</td>
              <td className="cell-center">
                {toIcelandicDate(item.latest_work_log)}
              </td>
              <td className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="default"
                  onClick={() => onEditProject(item)}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </td>
              <td className="text-center">
                <Button
                  bsSize="xsmall"
                  onClick={() => onChangeProjectState(item)}
                >
                  {item.is_open ? t("Close") : t("Open")}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function Projects({
  projects,
  sortedBy,
  onChangeProjectState,
  onEditProject,
  onSortProjects,
}) {
  const [showEditProject, setShowEditProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openEditProjectModal = project => {
    setShowEditProject(true);
    setSelectedProject(project);
  };
  const closeEditProjectModal = () => {
    setShowEditProject(false);
    setSelectedProject(null);
  };

  const closedProjects = projects.filter(project => !project.is_open);
  const hasClosedProjects = closedProjects.length !== 0;
  const projectID = (selectedProject && selectedProject.id) || 0;

  return (
    <div>
      {hasClosedProjects ? <h3>{t("Open projects")}</h3> : null}
      <ProjectsTable
        projects={projects.filter(project => project.is_open)}
        sortedBy={sortedBy}
        onEditProject={openEditProjectModal}
        onChangeProjectState={onChangeProjectState}
        onSortProjects={onSortProjects}
      />

      {hasClosedProjects ? (
        <div style={{ marginTop: "3em" }}>
          <h3>{t("Closed projects")}</h3>
          <ProjectsTable
            projects={closedProjects}
            sortedBy={sortedBy}
            onEditProject={openEditProjectModal}
            onChangeProjectState={onChangeProjectState}
            onSortProjects={onSortProjects}
          />
        </div>
      ) : null}

      <EditProject
        show={showEditProject}
        onSubmit={onEditProject}
        onCloseModal={closeEditProjectModal}
        project={selectedProject}
        key={projectID}
      />
    </div>
  );
}

export default Projects;
